require('./src/styles/common.scss');
require('intersection-observer');
const objectFitImages = require('object-fit-images');

const React = require('react');
const cookie = require('tiny-cookie');
const { ContactProvider } = require('./src/context/ContactContext');

const addScript = (url) => {
  const script = document.createElement('script');
  script.src = url;
  script.async = true;
  document.body.appendChild(script);
};

// Wrap root element
// eslint-disable-next-line react/prop-types
exports.wrapRootElement = ({ element }) => (
  // eslint-disable-next-line react/react-in-jsx-scope
  // eslint-disable-next-line react/jsx-filename-extension
  <ContactProvider>{element}</ContactProvider>
);

// Show loader on initial load only
// exports.onInitialClientRender = () => {
//   // Add polyfill fit images
//   const objectFitImageList = document.querySelectorAll('img.object-fit-image');
//   objectFitImages(objectFitImageList);

//   // Show loader only on homepage
//   if (window.location.pathname === '/') {
//     document.getElementById('loader').style.display = 'block';
//   }
// };

// Show loader if route is delayed
// exports.onRouteUpdateDelayed = () => {
//   // Show loader only on homepage
//   if (window.location.pathname === '/') {
//     document.getElementById('loader').style.display = 'block';
//   }
// };

exports.onRouteUpdate = ({ location }) => {
  // if (window.location.pathname === '/') {
  //   document.getElementById('loader').style.display = 'block';
  // }

  // Add polyfill fit images
  const objectFitImageList = document.querySelectorAll('img.object-fit-image');
  objectFitImages(objectFitImageList);

  /*
    getUrlParams: Read a page's GET URL variables and return them as an associative array
  */
  const getUrlParams = () => {
    const params = [];
    let hash;
    const hashes = location.search.split('&');
    for (let i = 0; i < hashes.length; i += 1) {
      hash = hashes[i].replace('?', '').split('=');
      params.push(hash[0].toLowerCase());
      params[hash[0].toLowerCase()] = hash[1];
    }
    return params;
  };

  const urlData = getUrlParams();
  if (urlData) {
    const fetchedCjEvent = urlData.cjevent;
    if (fetchedCjEvent) {
      if (cookie.getCookie('cjevent') !== fetchedCjEvent) {
        cookie.setCookie('cjevent', fetchedCjEvent, { expires: '30D' });
      }
    }
  }
};

exports.onClientEntry = () => {
  window.onload = () => {
    addScript('https://js.hsforms.net/forms/v2.js');
  };
};
