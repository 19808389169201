/* eslint-disable react/state-in-constructor */
import React, { createContext } from 'react';
import Proptypes from 'prop-types';

// Default State
const defaultState = {
  modal: false,
  toggleModal: () => {},
};

// Contact context
const ContactContext = createContext(defaultState);

// Contact provider
class ContactProvider extends React.Component {
  state = {
    modal: false,
  };

  handleOverflow = (modal) => {
    const bodyArea = document.getElementsByTagName('body')[0];
    if (!modal) {
      bodyArea.style.overflowY = 'hidden';
    } else {
      bodyArea.style.overflowY = 'auto';
    }
  };

  toggleModal = () => {
    this.handleOverflow(this.state.modal);
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  render() {
    const { children } = this.props;
    const { modal } = this.state;
    return (
      <ContactContext.Provider
        value={{
          modal,
          toggleModal: this.toggleModal,
        }}
      >
        {children}
      </ContactContext.Provider>
    );
  }
}

// Proptypes
ContactProvider.propTypes = {
  children: Proptypes.objectOf(Proptypes.object).isRequired,
};

export default ContactContext;
export { ContactProvider };
